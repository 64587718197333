import { Injectable, inject, signal } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
    providedIn: "root"
})
export class LocaleSettingsService {
    tanslocoService = inject(TranslocoService);

    //Serve a configurare il token LOCALE_ID di angular e tutti gli inputNumber di primeng
    locale = signal(this.getLang());
    constructor() {}

    getLang() {
        // let lang = "en";
        // if (navigator.languages != undefined) lang = navigator.languages[0];
        // if (navigator.language == undefined) lang = "en";
        // lang = navigator.language;
        // return lang.slice(0, 2);

        //Per ora è stata inserita solo la lingua italiana e importata solo la lingua it nel main ts
        return "it";
    }
}
